import React, { useState, useEffect, useCallback } from 'react';
import "./style.scss";
import Header from '../../components/header';
import Hero from '../../components/hero';
import Footer from '../../components/footer';
import SpaceCard from '../../components/space-card';
import axios from 'axios';
import { Constants } from '../../constants';
import AccessControll from '../../services/access';
import GaleryCarousel from '../../components/galery-carousel';
import Loading from '../../components/loading';

const ServiceSpace = () => {

    const [spaces, setSpaces] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [details, setDetails] = useState();

    const getSpaces = useCallback(async () => {
        setIsLoading(true)
        await axios.get(`${Constants.ApiUrl}/spaces`, {
            params: {
                filter: {
                    is_active: true
                }
            }
        })
            .then((response) => {
                setSpaces(response.data);
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)
            })
    }, [])

    const getPageDetails = useCallback(async () => {
        setIsLoading(true)
        const config = {
            params: {
                filter: {
                    "name": "espacos",
                }
            }
        }

        await axios.get(`${Constants.ApiUrl}/pages`, config)
        .then((response) => {
            setDetails(response.data[0]);
            setIsLoading(false)
        })
        .catch((err) => {
            console.log(err);
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {
        getSpaces();
        getPageDetails();
        AccessControll('Home Espaços');
    }, [getSpaces, getPageDetails]);

    return (
        <div id="spaces">
            <Loading loading={isLoading} />
            <Header />
            <Hero
                center={true}
                image={require("../../assets/images/projects-main-image.png")}
                h1={"Um mundo de auto conhecimento aguardando você"}
            />

            <div className={"blog-box"}>
                <h3 className={"page-title"}>Auditórios Mundo Akar</h3>
                <div className={"header"}>
                    {
                        details ?
                        <>
                            <img alt="main_image" className="back" src={details.main_image_url} />
                            <h3>{details.main_text}</h3>
                        </>
                        :
                        <span></span>
                    }
                </div>
                {
                    details ?
                    <GaleryCarousel images={details.images} />
                    :
                    <span></span>
                }
                <h3 className={"page-subtitle"}>Confira abaixo nossos auditórios</h3>
                <div className="flex-horizontal justify-around flex-wrap">
                    {spaces?.map((room) => {
                        return (
                            <SpaceCard
                                key={room.id}
                                id={room.id}
                                name={room.name}
                                thumbnail_url={room.thumbnail_url}
                                category={room.category}
                            />
                        )
                    })}
                </div>
            </div>

            <Footer backgroundImage={require("../../assets/images/orange-footer.png")}/>
        </div>
    );
}

export default ServiceSpace;