import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Menu, Dropdown } from "antd";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { logout } from "../../redux/actions/userActions";
import axios from "axios";
import { Constants } from "../../constants";
import "./style.scss";

const dropdownMundo = (
  <Menu>
    {/* <Menu.Item>
      <Link to={"/kids"}>Akar Kids</Link>
    </Menu.Item> */}
    <Menu.Item>
      <Link to={"/social"}>Akar Social</Link>
    </Menu.Item>
    {/* <Menu.Item>
      <Link to={"/experiencias"}>Experiências Akar Social</Link>
    </Menu.Item> */}
  </Menu>
);

const dropdownBlog = (
  <Menu>
    <Menu.Item>
      <Link to={"/midias"}>Mídias</Link>
    </Menu.Item>
    {/* <Menu.Item>
      <Link to={"/solidariedade"}>Fazer o bem</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={"/oportunidades"}>Oportunidades</Link>
    </Menu.Item> */}
  </Menu>
);

const dropdownServicos = (
  <Menu className={"dropdown"}>
    <Menu.Item>
      <Link to={"/salas"}>Salas de atendimento</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={"/espacos"}>Espaços/Auditórios</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={"/eventos"}>Eventos</Link>
    </Menu.Item>
    {/* <Menu.Item>
      <Link to={"/projetos"}>Projetos</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={"/coffee"}>Café</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={"/planos"}>Planos</Link>
    </Menu.Item> */}
    <Menu.Item>
      <Link to={"/contato"}>Contato</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to={"/sugestoes"}>Sugestões</Link>
    </Menu.Item>
  </Menu>
);

const dropdownAgenda = (
  <Menu>
    {/* <Menu.Item>
      <Link to={"/agenda-geral"}>Agenda geral</Link>
    </Menu.Item> */}
    <Menu.Item>
      <Link to={"/agenda-social"}>Agenda Akar Social</Link>
    </Menu.Item>
  </Menu>
);

const Header = () => {
  const [visible, setVisible] = useState(false);
  const [pages, setPages] = useState([])
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const dispatch = useDispatch();

  const handleMobileMenu = () => {
    setVisible(!visible);
  };

  const getPages = useCallback(async () => {
    const savedPages = localStorage.getItem("@mundo-akar:pages");
    if (savedPages) setPages(JSON.parse(savedPages));
    try {
      const { data } = await axios.get(`${Constants.ApiUrl}/pages-content`);
      setPages(data);
      localStorage.setItem("@mundo-akar:pages", JSON.stringify(data));
    } catch(e) {
      console.log(e);
    }
  }, [])

  useEffect(() => {
    getPages();
  }, [])

  return  (
    <div id="header" className={"flex-horizontal align-center justify-between"}>
      <Link to={"/"}>
        <img
          alt="logo"
          src={require("../../assets/images/logo-with-name.png")}
        />
      </Link>
      <ul className={"flex-horizontal align-center desktop-menu"}>
        <li>
          <Link className="header-button" to={"/"}>Home</Link>
        </li>
        <li>
          <Dropdown overlay={dropdownMundo}>
            <span>Mundo Akar</span>
          </Dropdown>
        </li>
        <li>
          <Dropdown overlay={dropdownServicos}>
            <span>Serviços</span>
          </Dropdown>
        </li>
        <li>
          <Dropdown overlay={dropdownAgenda}>
            <span>Agenda</span>
          </Dropdown>
        </li>
        <li>
          <Dropdown overlay={dropdownBlog}>
            <Link className="header-button" to={"/blog"}>Blog</Link>
          </Dropdown>
        </li>
        {pages.map(page => (
          <li>
            <Link key={page.slug} className="header-button" to={`/paginas/${page.slug}`}>{page.name}</Link>
          </li>
        ))}
      </ul>
      <Button type="primary" className={"btn btn-outline btn-login-logout"}>
        {userInfo ? (
          <>
            <Link to={"/minha-conta"}>Minha conta</Link>
            <ul className="logout-button">
              <Button
                onClick={() => dispatch(logout())}
                className={"btn btn-outline"}
              >
                Logout
              </Button>
            </ul>
          </>
        ) : (
          <Link to={"/login"}>Login</Link>
        )}
      </Button>
      <button
        className="menu-mobile-button"
        style={{ display: visible ? "none" : "" }}
        onClick={() => handleMobileMenu()}
      >
        <MenuOutlined />
      </button>
      <button
        className="menu-mobile-button"
        style={{ display: visible ? "" : "none" }}
        onClick={() => handleMobileMenu()}
      >
        <CloseOutlined />
      </button>
      <div
        className="mobile-dropdown"
        style={{ display: visible ? "" : "none" }}
      >
        <nav className="nav" role="navigation">
          <ul className="nav__list">
            <li>
              <input id="group-1" type="checkbox" hidden />
              <label for="group-1">
                <span></span>
                <Link
                    className="menu-item"
                    to={'/'}
                >
                    Home
                </Link>
              </label>
            </li>
            <li>
              <input id="group-2" type="checkbox" hidden />
              <label for="group-2">
                <span></span>Mundo Akar
              </label>
              <ul className="group-list">
                {/* <li>
                  <label for="sub-group-1">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/kids'}
                    >
                        Kids
                    </Link> 
                  </label>
                </li> */}
                <li>
                  <label for="sub-group-2">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/social'}
                    >
                        Akar Social
                    </Link>
                  </label>
                </li>
                {/* <li>
                  <label for="sub-group-3">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/experiencias'}
                    >
                        Experiências Akar Social
                    </Link>
                  </label>
                </li> */}
              </ul>
            </li>
            <li>
              <input id="group-3" type="checkbox" hidden />
              <label for="group-3">
                <span></span>Serviços
              </label>
              <ul className="group-list">
                {/* <li>
                  <label for="sub-group-7">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/Atendimento'}
                    >
                        Atendimento
                    </Link> 
                  </label>
                </li>
                <li>
                  <label for="sub-group-8">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/salas'}
                    >
                        Salas de atendimento
                    </Link>
                  </label>
                </li>
                <li>
                  <label for="sub-group-9">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/espacos'}
                    >
                        Espaços/Auditório
                    </Link>
                  </label>
                </li> */}
                <li>
                  <label for="sub-group-10">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/eventos'}
                    >
                        Eventos
                    </Link>
                  </label>
                </li>
                {/* <li>
                  <label for="sub-group-10">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/projetos'}
                    >
                        Projetos
                    </Link>
                  </label>
                </li>
                <li>
                  <label for="sub-group-11">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/cafe'}
                    >
                        Café
                    </Link>
                  </label>
                </li>
                <li>
                  <label for="sub-group-12">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/planos'}
                    >
                        Planos
                    </Link>
                  </label>
                </li> */}
                <li>
                  <label for="sub-group-13">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/contato'}
                    >
                        Contato
                    </Link>
                  </label>
                </li>
                <li>
                  <label for="sub-group-13">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/sugestoes'}
                    >
                        Sugestões
                    </Link>
                  </label>
                </li>
              </ul>
            </li>
            <li>
              <input id="group-4" type="checkbox" hidden />
              <label for="group-4">
                <span></span>Agenda
              </label>
              <ul className="group-list">
                {/* <li>
                  <label for="sub-group-14">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/agenda-geral'}
                    >
                        Agenda Geral
                    </Link> 
                  </label>
                </li> */}
                <li>
                  <label for="sub-group-15">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/agenda-social'}
                    >
                        Agenda Social
                    </Link>
                  </label>
                </li>
              </ul>
            </li>
            <li>
              <input id="group-5" type="checkbox" hidden />
              <label for="group-5">
                <span></span>Blog
              </label>
              <ul className="group-list">
                <li>
                  <label for="sub-group-16">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/blog'}
                    >
                        Primeira página
                    </Link> 
                  </label>
                </li>
                {/* <li>
                  <label for="sub-group-17">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/galeria'}
                    >
                        Galeria
                    </Link>
                  </label>
                </li>
                <li>
                  <label for="sub-group-18">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/solidariedade'}
                    >
                        Fazer o bem
                    </Link>
                  </label>
                </li>
                <li>
                  <label for="sub-group-19">
                    <span></span>
                    <Link
                        className="menu-item"
                        to={'/oportunidades'}
                    >
                        Oportunidades
                    </Link>
                  </label>
                </li> */}
              </ul>
            </li>
            <li>
              <input id="group-6" type="checkbox" hidden />
              <label for="group-6">
                <span></span>
                {userInfo ? (
                <Link className="menu-item" to={"/minha-conta"}>Minha conta</Link>
              ) : (
                <Link className="menu-item" to={"/login"}>Login</Link>
              )}
              </label>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
