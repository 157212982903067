import React, { useState, useEffect, useCallback } from "react";
import "../pages-content/style.scss";
import Header from "../../components/header";
import Hero from "../../components/hero";
import Footer from "../../components/footer";
import axios from "axios";
import { Constants } from "../../constants";
import Loading from "../../components/loading";
import { Link } from "react-router-dom";

const Gallery = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState();

  const getContent = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${Constants.ApiUrl}/galery`, {
        params: { range: `[0,1000]` },
      });
      setDetails(data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [props]);

  useEffect(() => {
    getContent();
  }, [props]);

  return (
    <div id="spaces">
      <Loading loading={isLoading} />
      <Header />
      <Hero
        center={true}
        image={require("../../assets/images/opportunities-main-image.png")}
        h1="Mídias"
      />

      <div className={"blog-box"}>
        <div className="flex-horizontal justify-around flex-wrap">
          {details?.map((gallery) => {
            return (
              <div className="space-card">
                <Link to={{ pathname: `/galeria/${gallery.id}` }}>
                    <div className="box">
                        <img src={gallery.image1_url} />
                        <div className="title_card">
                          {gallery.main_text}
                        </div>
                    </div>

                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <Footer
        backgroundImage={require("../../assets/images/orange-footer.png")}
      />
    </div>
  );
};

export default Gallery;
